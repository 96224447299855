<!-- src/components/StatusImage.vue -->
<template>
  <div>
    <img
      v-if="status === 0"
      class="img_status"
      src="@/assets/images/not_submitted.png"
      alt="Not Submitted"
    />
    <img
      v-if="status === 1"
      class="img_status"
      src="@/assets/images/Submitted.png"
      alt="Submitted"
    />
    <img
      v-if="status === 2"
      class="img_status"
      src="@/assets/images/evluated.png"
      alt="Evaluated"
    />
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.img_status {
  width: 20px;
  height: 20px;
}
</style>
